import React from "react";
import { Navbar, Container, Button } from "react-bootstrap";

const TopBar = ({ toggleSidebar }) => {
  return (
    <Navbar bg="dark" variant="dark" expand="lg" className="px-3 " style={{position:"fixed", top:"0",  }}>
      <Container fluid>
        <Button
          variant="outline-light"
          className="d-lg-none me-2"
          onClick={toggleSidebar}
        >
          ☰
        </Button>
        <Navbar.Brand href="#home">Admin Dashboard</Navbar.Brand>
      </Container>
    </Navbar>
  );
};

export default TopBar;
