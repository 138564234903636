import React, { useEffect, useState } from "react";
import TopBar from "./TopBar";
import SideBar from "./SideBar";
import '../components/css/dashboard.css';
import { FaBars } from "react-icons/fa";
import axios from "axios";
import Pagination from "@mui/material/Pagination";
import PaginationItem from "@mui/material/PaginationItem";
import Stack from "@mui/material/Stack";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";



const Dashboard = () => {
    // const [isSidebarOpen, setSidebarOpen] = useState(false);
    const [searchTerm, setSearchTerm] = useState("");
    const [page, setPage] = useState(1);


    const [isSidebarOpen, setIsSidebarOpen] = useState(true);
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
    // console.log("THIS API BASE URL " + API_BASE_URL);
    useEffect(() => {
        axios.get(`${API_BASE_URL}/fetch_data.php`) // Fetching from .env API URL
            .then((response) => {
                // console.log("API Response:", response.data);
                setData(Array.isArray(response.data) ? response.data : []);
                setLoading(false);
            })
            .catch((error) => {
                setError("Error fetching data");
                setLoading(false);
            });
    }, []);

    const toggleSidebar = () => {
        setIsSidebarOpen(!isSidebarOpen); // Toggle the sidebar state
    };
    // if (loading) return <p>Loading...</p>;
    // if (error) return <p>{error}</p>;




    // Sample table data
    const tableData = [
        { id: 1, name: "John Doe", email: "john@example.com", phone: "1234567890", course: "React Basics" },
        { id: 2, name: "Jane Smith", email: "jane@example.com", phone: "0987654321", course: "JavaScript Essentials" },
        { id: 3, name: "Alice Brown", email: "alice@example.com", phone: "1122334455", course: "Bootstrap Mastery" },
        { id: 4, name: "Bob Johnson", email: "bob@example.com", phone: "6677889900", course: "Advanced CSS" },
    ];

    const filteredData = data.filter((item) =>
        Object.values(item).some((val) =>
            val.toString().toLowerCase().includes(searchTerm.toLowerCase())
        )
    );




    // pagination for table 
    const rows = 8;
    const totalpage = Math.ceil(data.length / rows);

    const currentPage = data.slice((page - 1) * rows, page * rows);
    const handleChange = (event, value) => {
        setPage(value);
    }

    return (
        <div className="dashboard">
            {/* TopBar Component */}
            <TopBar toggleSidebar={toggleSidebar} />

            {/* Main Container */}
            <div className="d-flex">
                {/* Toggle Button for mobile */}
                <button
                    className="btn btn-dark d-lg-none"
                    onClick={toggleSidebar}
                    style={{
                        position: "absolute",
                        top: "10px",
                        left: "10px",
                        zIndex: 9999,
                    }}
                >
                    <FaBars />
                </button>
                {/* Sidebar Component */}
                {/* Sidebar */}
                <div
                    className="sidebar"
                    style={{
                        width: isSidebarOpen ? "250px" : "0",
                        height: "100vh",
                        transition: "width 0.3s ease",
                    }}
                >
                    <SideBar isOpen={isSidebarOpen} toggleSidebar={toggleSidebar} />
                </div>

                <div
                    className={`content p-4 ${isSidebarOpen ? "content-open" : "content-closed"}`}
                >
                    <h1 className="mt-5">Welcome to the Admin Dashboard</h1>


                    <div className="search-bar mb-4">
                        <input
                            type="text"
                            className="form-control"
                            placeholder="Search by any keyword..."
                            value={searchTerm}
                            onChange={(e) => setSearchTerm(e.target.value)}
                        />
                    </div>

                    {/* Table */}
                    <div className="table-responsive">
                        <table className="table table-bordered">
                            <thead className="thead-dark">
                                <tr>
                                    <th>ID</th>
                                    <th>Name</th>
                                    <th>Email</th>
                                    <th>Phone</th>
                                    <th>Subject </th>
                                    <th>Preferred Method Of Contact</th>
                                    <th>Description</th>
                                </tr>
                            </thead>
                            <tbody>
                                {filteredData.length > 0 ? (
                                    filteredData.map((item) => (
                                        <tr key={item.id}>
                                            <td>{item.id}</td>
                                            <td>{item.full_name}</td>
                                            <td>{item.email_address}</td>
                                            <td>{item.phone_number}</td>
                                            <td>{item.subject}</td>
                                            <td>{item.preferred_contact}</td>
                                            <td>{item.description}</td>
                                        </tr>
                                    ))
                                ) : (
                                    <tr>
                                        <td colSpan="5" className="text-center">
                                            No  records found.
                                        </td>
                                    </tr>
                                )}
                            </tbody>
                        </table>
                    </div>
                    {/* Pagination */}
                    {totalpage > 1 && (
                        <Stack spacing={2} alignItems="center" className="mt-3">
                            <Pagination
                                count={totalpage}
                                page={page}
                                onChange={handleChange}
                                renderItem={(item) => (
                                    <PaginationItem
                                        slots={{ previous: ArrowBackIcon, next: ArrowForwardIcon }}
                                        {...item}
                                    />
                                )}
                            />
                        </Stack>
                    )}
                </div>
                {/* </div> */}
            </div>

            {/* <div className="d-flex">
                <div className="footer">11111111111111111</div>
                <div className="footer">2222222222222222222</div>
            </div> */}
        </div >
    );
};

export default Dashboard;
