import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

const Login = () => {
    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");
    const [isLoaded, setIsLoaded] = useState(false);

    useEffect(() => {
        // Adding a small delay to animate smoothly
        setTimeout(() => setIsLoaded(true), 300);
    }, []);

    const navigate = useNavigate();

    // Hardcoded admin credentials
    const ADMIN_CREDENTIALS = {
        username: "khapra-admin",
        password: "admin#&^*&123",
    };

    const handleLogin = (e) => {
        e.preventDefault();

        // console.log("Entered Username:", username);
        // console.log("Entered Password:", password);

        // Check if credentials match
        if (username === ADMIN_CREDENTIALS.username && password === ADMIN_CREDENTIALS.password) {
            // Save admin data in localStorage
            localStorage.setItem("admin", JSON.stringify({ username }));
            // console.log("Login successful, admin data saved in localStorage:", localStorage.getItem("admin"));

            // Redirect to the dashboard after successful login
            navigate("/dashboard");
        } else {
            console.log("Invalid credentials!");
            alert("Invalid username or password!");
        }
    };

    return (
        <div className={`login-container ${isLoaded ? "loaded" : ""}`}>
            <div className="login-box">
                <h2 className="text-center">Admin Login</h2>
                <form className="login-form" onSubmit={handleLogin}>
                    <div className="form-group">
                        <label>Username</label>
                        <input
                            type="text"
                            className="form-control"
                            value={username}
                            onChange={(e) => setUsername(e.target.value)}
                            required
                        />
                    </div>
                    <div className="form-group">
                        <label>Password</label>
                        <input
                            type="password"
                            className="form-control"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                            required
                        />
                    </div>
                    <button type="submit" className="btn btn-primary w-100">
                        Login
                    </button>
                </form>
            </div>
        </div>
    );
};

export default Login;
