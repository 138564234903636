import React, { useState } from "react";
import { Nav } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { FaHome, FaSignOutAlt, FaBars } from "react-icons/fa"; // Example icons from React Icons

const SideBar = () => {
    const [isOpen, setIsOpen] = useState(true); // Sidebar state
    const navigate = useNavigate();

    const handleLogout = () => {
        // Remove the admin data from localStorage
        localStorage.removeItem("admin");

        // Redirect to the login page
        navigate("/login");
    };

    const toggleSidebar = () => {
        setIsOpen(!isOpen); // Toggle the sidebar visibility
    };

    return (
        <div>
            {/* Toggle button for mobile */}
            <button
                className="btn btn-dark d-lg-none"
                onClick={toggleSidebar}
                style={{
                    position: "absolute",
                    top: "10px",
                    left: "10px",
                    zIndex: 9999
                }}
            >
                <FaBars /> {/* Hamburger icon */}
            </button>

            {/* Sidebar */}
            <div
                className={`sidebar bg-dark text-white ${isOpen ? "d-block" : "d-none"} d-lg-block`}
                style={{
                    width: "250px",
                    position: "fixed",
                    bottom: 0,
                    left: 0,
                    height: "100vh",
                    borderTopRightRadius: "10px", // Optional: rounded top corners
                }}
            >
                <Nav className="flex-column p-3" style={{ flexGrow: 1 }}>
                    {/* Dashboard link with icon */}
                    <Nav.Link href="/dashboard" className="text-white">
                        <FaHome className="mr-2" /> Dashboard
                    </Nav.Link>

                    {/* Add other navigation links here */}
                </Nav>

                {/* Move the Logout button to the bottom */}
                <Nav className="flex-column p-3">
                    <Nav.Link href="#" className="text-white" onClick={handleLogout}>
                        <FaSignOutAlt className="mr-2" /> Logout
                    </Nav.Link>
                </Nav>
            </div>
        </div>
    );
};

export default SideBar;
