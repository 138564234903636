// src/App.js
import React from "react";
import { BrowserRouter as Router, Route, Routes, Navigate } from "react-router-dom";
import Layout from "./components/Layout";
import Home from "./Pages/Home";
import AboutUs from "./Pages/AboutUs";
import Expertise from "./Pages/Expertise";
import OurTeam from "./Pages/OurTeam";
import NewsAndInsights from "./Pages/NewsAndInsights";
import Careers from "./Pages/Careers";
import ContactUs from "./Pages/ContactUs";
import CriminalLawDetails from "./Pages/CriminalLawDetails";
import Dashboard from "./Pages/Dashboard";
import Login from "./Pages/Login";
import "bootstrap/dist/css/bootstrap.min.css";

function App() {
  const isLoggedIn = !!localStorage.getItem("admin"); 

  return (
    <Router>
      <Routes>
        {/* Pages with Header & Footer */}
        <Route
          path="/*"
          element={
            <Layout>
              <Routes>
                <Route path="/" element={<Home />} />
                <Route path="/about" element={<AboutUs />} />
                <Route path="/expertise" element={<Expertise />} />
                <Route path="/our-team" element={<OurTeam />} />
                <Route path="/news-and-insights" element={<NewsAndInsights />} />
                <Route path="/careers" element={<Careers />} />
                <Route path="/contact" element={<ContactUs />} />
                <Route path="/criminal-law-details/:id" element={<CriminalLawDetails />} />
              </Routes>
            </Layout>
          }
        />

        {/* Pages without Header & Footer */}
        <Route path="/login" element={<Login />} />
        <Route path="/dashboard" element={isLoggedIn ? <Dashboard /> : <Navigate to="/login" />} />
      </Routes>
    </Router>
  );
}

export default App;
